export class Animate  {

  static init() {

    const observer = new IntersectionObserver(entries => {
      // Loop over the entries
      entries.forEach(entry => {
        // If the element is visible
        if (entry.isIntersecting) {
          // Add the animation class
          // entry.target.classList.add('animate-' + entry.target.getAttribute('data-viewport-animation'));
          window.setTimeout(() => {
            entry.target.classList.add('animate');
          }, 100);
        }
      });
    }, {
      rootMargin: '40px',
      threshold: 0.5,
    });

    document.querySelectorAll('[class*="animate-"]').forEach(element => observer.observe(element));
  }

}
