import { Collapse } from 'bootstrap'

const scrollRootElement = document.documentElement;

export class Navigation {

  static init() {

    const navigation = document.getElementById('wb-navbar');

    const thresholdElement = document.querySelector('.wb-stage .container');
    const threshold = thresholdElement.clientHeight + thresholdElement.offsetTop;

    // on scroll -> handle scroll behavior
    function handleScroll() {
      if (scrollRootElement.scrollTop > threshold) {
        // show button
        navigation.classList.add('is-fixed');
      } else {
        // hide button
        navigation.classList.remove('is-fixed');
      }
    }

    document.addEventListener('scroll', handleScroll);

    const navLinks = document.querySelectorAll('.nav-item');
    const menuToggle = document.getElementById('wb-navbar-toggler');
    const bsCollapse = new Collapse(menuToggle, {toggle: false});
    navLinks.forEach((l) => {
      l.addEventListener('click', () => { bsCollapse.toggle() });
    })
  }
}
