import { Betreiber, Dienstleister } from './constants';

export class MapPopover {

  active = undefined;

  constructor(containerElement, icons) {
    this.containerElement = containerElement;
    this.icons = icons;
  }

  addListener(marker, feature) {
    marker.addListener('click', () => {
      this.activate(marker, feature);
    });
  }

  deactivateAll() {

    if (this.active) {
      this.active.marker.setIcon(this.icons.default);
      this.active.marker.setZIndex(1);
    }

    document.querySelectorAll('#mapPopover .wb-popover').forEach(element => {
      element.classList.remove('active');
      window.setTimeout(() => {
        element.remove();
      }, 250);
    });

    document.body.classList.remove('no-scroll');

  }

  activate(marker, feature) {

    this.deactivateAll();

    this.active = { feature, marker };

    marker.setIcon(this.icons.active);
    marker.setZIndex(10000);

    const popover = document.createElement('div');
    popover.classList.add('wb-popover', 'wb-map-box', 'wb-map-box-toggled');

    const closeButton = document.createElement('a');
    closeButton.classList.add('wb-map-box-close');
    closeButton.onclick = () => {
      this.deactivateAll();
    };

    const logo = Betreiber[feature.Betreiber].logo;

    const header = document.createElement('div');
    header.classList.add('wb-popover-header');

    header.append(closeButton);
    popover.prepend(header);

    const content = document.createElement('div');
    content.classList.add('wb-popover-content');

    const packagesFrom = Dienstleister.filter(d => feature[d.receiveMapping] === 'x').map(d => d.label);
    const packagesTo = Dienstleister.filter(d => feature[d.retoureMapping] === 'x').map(d => d.label);

    content.innerHTML = `
      <hr/>
      <div class=" wb-overflow-y">
        <div class="wb-popover-padding">
          <span class="wb-map-label wb-popover-beitreiber-label">Betreiber*in</span>
          <img class="wb-popover-betreiber-logo" src="images/betreiber/${logo}" alt="${feature.Betreiber}"/>
          <span class="wb-popover-address">
            ${feature.Adresse}<br/>
            ${feature.Postleitza} ${feature.Ort}
          </span>
          ${feature.eingeschr.trim().length > 0 ? `<span class="wb-popover-hint">${feature.eingeschr}</span>` : ''}
        </div>
        <hr/>
        <div class="wb-popover-padding">
          <a class="wb-popover-options-toggle" data-bs-toggle="collapse" href="#mapPopoverOptions" aria-expanded="false" aria-controls="mapPopoverOptions">
            Optionen <img src="images/arrow-collapse.png" alt="Zur Website"/>
          </a>
        </div>
        <div class="collapse" id="mapPopoverOptions">
          <div class="wb-popover-padding">
          
            ${packagesFrom.length > 0 ? `<div class="wb-popover-options-item">
              <img src="images/card_empfangen.png" alt="Paket empfangen" />
              <h3 class="h2">Paket empfangen</h3>
              <p>von ${packagesFrom.join(', ')}</p>
            </div>` : ''}
            
            ${packagesTo.length > 0 ? `<div class="wb-popover-options-item">
              <img src="images/card_retoure.png" alt="Retoure aufgeben" />
              <h3 class="h2">Retoure aufgeben</h3>
              <p>an ${packagesTo.join(', ')}</p>
            </div>` : ''}
            
            ${feature.Sendungen === 'x' ? `<div class="wb-popover-options-item">
              <img src="images/card_haendler.png" alt="Sendung empfangen" />
              <h3 class="h2">Sendung empfangen</h3>
              <p>von lokalen Händler*innen</p>
            </div>` : ''}
            ${feature.Waren_aufg === 'x' ? `<div class="wb-popover-options-item">
              <img src="images/card_dienstleistung.png" alt="Waren empfangen" />
              <h3 class="h2">Waren aufgeben</h3>
              <p>für eine Dienstleistung</p>
            </div>` : ''}
            ${feature.Sendung_au === 'x' ? `<div class="wb-popover-options-item">
              <img src="images/card_uebergeben.png" alt="Sendung empfangen" />
              <h3 class="h2">Sendung aufgeben</h3>
              <p>an Personen oder Unternehmen</p>
            </div>` : ''}
            ${feature.Einlagern === 'x' ? `<div class="wb-popover-options-item">
              <img src="images/card_einlagern.png" alt="Sendung empfangen" />
              <h3 class="h2">Einlagern</h3>
              <p>Dinge kurzfristig einlagern und wieder abholen</p>
            </div>` : ''}
            ${feature['Gekühlte'] === 'x' ? `<div class="wb-popover-options-item">
              <img src="images/card_einfrieren.png" alt="Sendung empfangen" />
              <h3 class="h2">Gekühlt empfangen</h3>
              <p>Lebensmittel von Händler*innen aus der Region</p>
            </div>` : ''}
          </div>
        </div>
        <hr/>
        <div class="wb-popover-padding">
          <span class="wb-popover-link">
            <a href="https://${feature.Info}" target="_blank">
              Zur Website <img src="images/arrow-website.png" alt="Zur Website"/>
            </a>
          </span>
        </div>
      </div>
      <a class="btn btn-primary btn-lg wb-map-box-overlay-button" title="Schließen">Schließen</a>
    `;

    popover.appendChild(content);

    this.containerElement.appendChild(popover);
    window.setTimeout(() => popover.classList.add('active'), 50);

    // close filter box
    popover.querySelector('.wb-map-box-overlay-button').addEventListener('click', () => {
      this.deactivateAll();
    });

  }

}
