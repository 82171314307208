export const getMapIcons = (googleMaps) => ({
  default: {
    url: 'images/map_marker.png',
    size: new googleMaps.Size(56, 68),
    scaledSize: new googleMaps.Size(28, 34),
    origin: new googleMaps.Point(0, 0),
    anchor: new googleMaps.Point(14, 34),
  },
  active: {
    url: 'images/map_marker_active.png',
    size: new googleMaps.Size(128, 142),
    scaledSize: new googleMaps.Size(64, 71),
    origin: new googleMaps.Point(0, 0),
    anchor: new googleMaps.Point(32, 71),
  },
  location: {
    url: 'images/map_marker_location.png',
    size: new googleMaps.Size(80, 80),
    scaledSize: new googleMaps.Size(40, 40),
    origin: new googleMaps.Point(0, 0),
    anchor: new googleMaps.Point(20, 20),
  },
});
