export const Betreiber = {
  'A1': {
    label: 'A1',
    logo: 'a1.jpg',
  },
  'MYFLEXBOX': {
    label: 'MYFLEXBOX',
    logo: 'MFB_logo.svg',
  },
  'Renz': {
    label: 'Renz',
    logo: 'renz.png',
  },
  'Storebox': {
    label: 'Storebox',
    logo: 'storebox.svg',
  },
  'Tamburi': {
    label: 'Tamburi',
    logo: 'tamburi.png',
  },
  'Variocube': {
    label: 'Variocube',
    logo: 'variocube.png',
  },
  'ROSYS': {
    label: 'Rosy\'s',
    logo: 'rosys.jpg',
  },
  'TableConnect': {
    label: 'TableConnect',
    logo: 'tableconnect.jpg',
  },
};

export const Dienstleister = [
  {
    label: 'Österreichische Post',
    receiveMapping: 'Pak_Post',
    retoureMapping: 'Ret_Post',
  },
  {
    label: 'DPD',
    receiveMapping: 'Pak_DPD',
    retoureMapping: 'Ret_DPD',
  },
  {
    label: 'GLS',
    receiveMapping: 'Pak_GLS',
    retoureMapping: 'Ret_GLS',
  },
  {
    label: 'DHL Express',
    receiveMapping: 'Pak_DHL',
    retoureMapping: 'Ret_DHL',
  },
  {
    label: 'UPS',
    receiveMapping: 'Pak_UPS',
    retoureMapping: 'Ret_UPS',
  },
  {
    label: 'Hermes',
    receiveMapping: 'Pak_Hermes',
    retoureMapping: 'Ret_Hermes',
  },
  {
    label: 'Amazon',
    receiveMapping: 'Pak_Amazon',
    retoureMapping: 'Ret_Amazon',
  },
];
