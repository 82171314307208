const scrollToTopBtn = document.getElementById('scrollToTopBtn');
const scrollRootElement = document.documentElement;

export class ScrollToTop {

  static init() {
    // on scroll -> handle scroll behavior
    function handleScroll() {
      let scrollTotal = scrollRootElement.scrollHeight - scrollRootElement.clientHeight;
      if ((scrollRootElement.scrollTop / scrollTotal) > 0.05) {
        // show button
        scrollToTopBtn.classList.add('is-shown');
      } else {
        // hide button
        scrollToTopBtn.classList.remove('is-shown');
      }
    }

    document.addEventListener('scroll', handleScroll);

    // on click -> scroll to top
    scrollToTopBtn.onclick = function (clickEvent) {
      clickEvent = window.event;
      clickEvent.preventDefault();

      scrollRootElement.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    };
  }
}
