// Load Bootstrap JS
import { Modal } from 'bootstrap'
import { Map } from './map';
import { ScrollToTop } from './scrolltotop';
import { Animate } from './animate';
import { Navigation } from './navigation';

// Load Styles
import '../scss/main.scss';

document.addEventListener("DOMContentLoaded", function() {

  if (window.location.hash.substr(1) === 'datenschutz') {
    const myModal = new Modal(document.getElementById('datenschutz'));
    myModal.show();
  }

  Map.init();
  ScrollToTop.init();
  Animate.init();
  Navigation.init();
});
