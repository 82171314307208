import { MapPopover } from './popover';
import { getMapIcons } from './icons';
import { Betreiber, Dienstleister } from './constants';

const loadGoogleMapsApi = require('load-google-maps-api');
const { MapTheme } = require('./theme');

const toggleArray = (arr, value, checked) => {
  if (arr.includes(value) && !checked) {
    arr = arr.filter(b => b !== value);
  } else if (!arr.includes(value) && checked) {
    arr = [...arr, value];
  }
  return arr;
};

export class Map {

  googleMaps = undefined;
  mapInstance = undefined;
  popovers = undefined;

  boxes = [];
  markers = [];

  currentLocationMarker = undefined;

  filterBetreiber = [];
  filterOptions = [];
  filterDienstleisterReceive = [];
  filterDienstleisterRetoure = [];
  filterPermanently = false;

  mapElement = document.getElementById('map');
  mapPopoverElement = document.getElementById('mapPopover');
  mapSearchInput = document.getElementById("mapSearchInput");
  currentLocationButton = document.getElementById("currentLocation");
  mapFilterContainer = document.getElementById("mapFilter");
  mapFilterButton = document.getElementById("toggleFilterButton");
  betreiberSelectButton = document.getElementById("betreiberSelectButton");
  receiveDienstleisterButton = document.getElementById("receiveDienstleisterButton");
  retoureDienstleisterButton = document.getElementById("retoureDienstleisterButton");

  static init() {
    loadGoogleMapsApi({ key: process.env.GOOGLEMAPS_KEY, libraries: ['places'] }).then((googleMaps) => {
      return new Map(googleMaps);
    });
  }

  constructor(googleMaps) {

    this.googleMaps = googleMaps;

    this.mapInstance = new googleMaps.Map(this.mapElement, {
      styles: MapTheme,
      center: { lat: 48.2059236, lng: 16.3640453 },
      zoom: 12,
      disableDefaultUI: true,
      zoomControl: true,
      gestureHandling: 'cooperative',
    });

    this.loadBoxes();
    this.setupAutocomplete();
    this.setupCurrentLocation();
    this.setupFilters();
  }

  loadBoxes() {
    fetch('./boxes.json').then(async response => {

      this.boxes = (await response.json()).features.filter(({ properties }) => properties.WienBox_ === 'x');

      // console.log(new Set(this.boxes.map(b => b.properties.Betreiber)));

      const icons = getMapIcons(this.googleMaps);

      this.popovers = new MapPopover(this.mapPopoverElement, icons);

      this.boxes.forEach(({ properties }, idx) => {

        const { X, Y, Betreiber, Adresse } = properties;

        const marker = new this.googleMaps.Marker({
          position: new this.googleMaps.LatLng(Y, X),
          icon: icons.default,
          title: `${Betreiber} - ${Adresse}`,
          shadow: true,
          shape: 'circle,',
          map: this.mapInstance,
          optimized: false,
        });

        this.markers.push({ marker, properties });
        this.popovers.addListener(marker, properties);
      });

      this.filterMarkers();

    });
  }

  // autocomplete functionality
  setupAutocomplete() {
    const autocomplete = new this.googleMaps.places.Autocomplete(this.mapSearchInput, {
      fields: ["formatted_address", "geometry", "name"],
      strictBounds: false,
    });
    autocomplete.bindTo("bounds", this.mapInstance);

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();

      if (!place.geometry) {
        this.setCurrentLocationMarker(undefined);
        return;
      }

      if (place.geometry.location) {
        this.setCurrentLocationMarker(place.geometry.location);
      }

      if (place.geometry.viewport) {
        this.mapInstance.fitBounds(place.geometry.viewport);
        this.mapInstance.setZoom(16);
      } else {
        this.mapInstance.setCenter(place.geometry.location);
        this.mapInstance.setZoom(16);
      }
    });
  }

  setCurrentLocationMarker(pos) {
    if (this.currentLocationMarker) {
      this.currentLocationMarker.setMap(null);
      this.currentLocationMarker = undefined;
    }

    if (pos) {
      this.currentLocationMarker = new this.googleMaps.Marker({
        position: pos,
        icon: getMapIcons(this.googleMaps).location,
        shadow: true,
        shape: 'circle,',
        map: this.mapInstance,
      });
    }
  }

  // current location button
  setupCurrentLocation() {
    if (!navigator.geolocation) {
      this.currentLocationButton.classList.add('visually-hidden');
    }

    this.currentLocationButton.addEventListener("click", () => {
      // Try HTML5 geolocation.
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };

            this.mapInstance.setCenter(pos);
            this.mapInstance.setZoom(16);

            this.setCurrentLocationMarker(pos);

          }
        );
      }
    });
  }

  filterMarkers() {

    this.markers.forEach(({ marker, properties }) => {

      let visible = true;

      // filter betreiber
      if (this.filterBetreiber.length > 0 && this.filterBetreiber.length < Object.keys(Betreiber).length) {
        this.betreiberSelectButton.innerHTML = this.filterBetreiber.length === 1 ? Betreiber[this.filterBetreiber[0]].label : `${this.filterBetreiber.length} Betreiber gewählt`;
        if (!this.filterBetreiber.includes(properties.Betreiber)) {
          visible = false;
        }
      } else {
        this.betreiberSelectButton.innerHTML = 'Alle Betreiber*innen';
      }

      const filterDienstleister = (arr, mapping, button) => {
        if (arr.length > 0 && arr.length < Dienstleister.length) {
          button.innerHTML = arr.length === 1 ? Dienstleister[arr[0]].label : `${arr.length} Dienstleister gewählt`;
          if (arr.filter(d => properties[Dienstleister[d][mapping]] === 'x').length === 0) {
            visible = false;
          }
        } else {
          if (Dienstleister.filter(d => properties[d[mapping]] === 'x').length === 0) {
            visible = false;
          }
          button.innerHTML = 'Alle Dienstleister';
        }
      };

      // filter paket empfangen
      if (this.filterOptions.includes('paketEmpfangen')) {
        filterDienstleister(this.filterDienstleisterReceive, 'receiveMapping', this.receiveDienstleisterButton);
      }

      // filter retoure aufgeben
      if (this.filterOptions.includes('retoureAufgeben')) {
        filterDienstleister(this.filterDienstleisterRetoure, 'retoureMapping', this.retoureDienstleisterButton);
      }

      // filter sendung empfangen
      if (this.filterOptions.includes('sendungEmpfangen') && properties.Sendungen !== 'x') {
        visible = false;
      }

      // filter waren aufgeben
      if (this.filterOptions.includes('warenAufgeben') && properties.Waren_aufg !== 'x') {
        visible = false;
      }

      // filter sendung aufgeben
      if (this.filterOptions.includes('sendungAufgeben') && properties.Sendung_au !== 'x') {
        visible = false;
      }

      // filter einlagern
      if (this.filterOptions.includes('einlagern') && properties.Einlagern !== 'x') {
        visible = false;
      }

      // filter gekühlt empfangen
      if (this.filterOptions.includes('einfrieren') && properties['Gekühlte'] !== 'x') {
        visible = false;
      }

      // filter permanent
      if (this.filterPermanently && properties.eingeschr.trim().length > 0) {
        visible = false;
      }

      marker.setVisible(visible);

    });

  }

  // filter logic
  setupFilters() {

    // close filter box
    document.getElementById('closeMapFilter').addEventListener('click', () => {
      this.mapFilterButton.classList.remove('hidden');
      this.mapFilterContainer.classList.remove('active');
    });

    // close filter box
    document.querySelector('#mapFilter .wb-map-box-overlay-button').addEventListener('click', () => {
      this.mapFilterButton.classList.remove('hidden');
      this.mapFilterContainer.classList.remove('active');
    });

    // open filter box
    document.getElementById('toggleFilterButton').addEventListener('click', () => {
      this.mapFilterButton.classList.toggle('hidden');
      this.mapFilterContainer.classList.toggle('active');
    });

    if (window.innerWidth >= 992) {
      this.mapFilterButton.classList.add('hidden');
      this.mapFilterContainer.classList.add('active');
    }

    // setup betreiber filter

    const betreiberSelectList = document.querySelector('#betreiberSelect ul');

    for (const [key, value] of Object.entries(Betreiber)) {
      const li = document.createElement('li');
      li.innerHTML = `
        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="${key}" id="betreiberSelect${key}">
          <label class="form-check-label" for="betreiberSelect${key}">
            ${value.label}
          </label>
        </div>
      `;

      betreiberSelectList.append(li);
    }

    betreiberSelectList.querySelectorAll('input').forEach((input) => {
      input.addEventListener('change', (event) => {
        const { value, checked } = event.target;
        this.filterBetreiber = toggleArray(this.filterBetreiber, value, checked);
        this.filterMarkers();
      });
    });

    // setup options filters
    document.querySelectorAll('.map-filter-option').forEach((input) => {
      input.addEventListener('change', (event) => {
        const { value, checked } = event.target;
        this.filterOptions = toggleArray(this.filterOptions, value, checked);

        if (input.value === 'paketEmpfangen') {
          document.getElementById('receiveDienstleisterSelect').classList.toggle('visually-hidden');
        }

        if (input.value === 'retoureAufgeben') {
          document.getElementById('retoureDienstleisterSelect').classList.toggle('visually-hidden');
        }

        this.filterMarkers();
      });
    });

    // setup dienstleister filter

    const receiveDienstleisterSelectList = document.querySelector('#receiveDienstleisterSelect ul');
    const retoureDienstleisterSelectList = document.querySelector('#retoureDienstleisterSelect ul');

    Dienstleister.forEach((dienstleister, index) => {

      const createLi = (type) => {
        const li = document.createElement('li');
        li.innerHTML = `
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="${index}" id="${type}receiveDienstleisterSelect${index}">
            <label class="form-check-label" for="${type}receiveDienstleisterSelect${index}">
              ${dienstleister.label}
            </label>
          </div>
        `;
        return li;
      };

      receiveDienstleisterSelectList.append(createLi('receive'));
      retoureDienstleisterSelectList.append(createLi('retoure'));

    });

    receiveDienstleisterSelectList.querySelectorAll('input').forEach((input) => {
      input.addEventListener('change', (event) => {
        const { value, checked } = event.target;
        this.filterDienstleisterReceive = toggleArray(this.filterDienstleisterReceive, value, checked);
        this.filterMarkers();
      });
    });

    retoureDienstleisterSelectList.querySelectorAll('input').forEach((input) => {
      input.addEventListener('change', (event) => {
        const { value, checked } = event.target;
        this.filterDienstleisterRetoure = toggleArray(this.filterDienstleisterRetoure, value, checked);
        this.filterMarkers();
      });
    });

    // permanent filter
    const permanentFilter = document.getElementById('mapFilterPermanently');
    permanentFilter.addEventListener('change', (event) => {
      this.filterPermanently = event.target.checked;
      this.filterMarkers();
    });

  }
}
